<template>
  <div>
    <b-modal
        ref="color-picker-modal"
        size="xs"
        :title="`${title}`"
        @ok="onConfirmClick"
        ok-variant="success"
    >
      <sketch-picker v-model="colorValue"></sketch-picker>
    </b-modal>
    <button type="button" @click="onOpenModal" class="btn w-100 color-picker-button" :style="`background: #${color}`"></button>
  </div>
</template>

<script>
import { Sketch } from 'vue-color';

export default {
  name: "ColorPicker",
  components: {'sketch-picker': Sketch},
  props: {
    onConfirm: Function,
    title: String,
    color: String,
  },
  data: () => ({
    colorValue: '',
  }),
  methods: {
    onConfirmClick(){
      this.onConfirm(this.colorValue.hex8.replace('#', ''));
    },
    onOpenModal(){
      this.$refs["color-picker-modal"].show();
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.$refs["confirm_modal"].show();
      } else {
        this.$refs["confirm_modal"].hide();
      }
    },
    color(newValue) {
      this.colorValue = newValue;
    }
  },
  created: function () {
    this.colorValue = this.color;
  }
};
</script>

<style scoped></style>
