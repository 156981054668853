<template>
  <loader v-if="isLoadingSettings"/>
  <div v-else class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Settings</h3>
        </div>
      </div>

      <form v-on:submit="onSave">
        <div class="kt-portlet__body kt-portlet__body--fluid">
          <div class="kt-form kt-form--label-right w-100">
            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Enable</label>
              <div class="col-lg-9 col-xl-6">
                <div class="kt-input-icon kt-input-icon--right">
                  <div class="kt-switch_block ">
                        <span
                            :class="`kt-switch kt-switch--sm`"
                            @click="e => onToggle(e, 'enabled')"
                        >
                          <label class="mb-0">
                            <input
                                type="checkbox"
                                :checked="!!settings.enabled"
                                name=""
                            />
                            <span/>
                          </label>
                        </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Button text</label>
              <div class="col-lg-9 col-xl-6">
                <input
                    class="form-control"
                    v-model="settings.button_text"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Button position</label>
              <div class="col-lg-9 col-xl-6 app-select">
                <v-select
                    :options="button_position_option"
                    v-model="settings.button_position"
                    :getOptionLabel="option => option.label"
                    :reduce="option => `${option.value}`"
                    :clearable="false"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Button bounce effect</label>
              <div class="col-lg-9 col-xl-6 app-select">
                <v-select
                    :options="button_bounce_option"
                    v-model="settings.button_bounce"
                    :getOptionLabel="option => option.label"
                    :reduce="option => `${option.value}`"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-4 col-lg-3 col-form-label">Use custom button styles</label>
              <div class="col-lg-9 col-xl-6">
                <div class="kt-input-icon kt-input-icon--right">
                  <div class="kt-switch_block ">
                      <span
                          :class="`kt-switch kt-switch--sm`"
                          @click="e => onToggle(e, 'custom_styles')"
                      >
                        <label class="mb-0">
                          <input
                              type="checkbox"
                              :checked="!!settings.custom_styles"
                              name=""
                          />
                          <span/>
                        </label>
                      </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="settings.custom_styles" class="mt-4">

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Button color</label>
                <div class="col-lg-9 col-xl-6">
                  <ColorPicker :onConfirm="(value) => {settings.button_color = value}" :color="settings.button_color"
                               title="Button color"/>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Button hover color</label>
                <div class="col-lg-9 col-xl-6">
                  <ColorPicker :onConfirm="(value) => {settings.button_hover_color = value}"
                               :color="settings.button_hover_color" title="Button hover color"/>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Button text color</label>
                <div class="col-lg-9 col-xl-6">
                  <ColorPicker :onConfirm="(value) => {settings.button_text_color = value}"
                               :color="settings.button_text_color" title="Button text color"/>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Button text color on hover</label>
                <div class="col-lg-9 col-xl-6">
                  <ColorPicker :onConfirm="(value) => {settings.button_hover_text_color = value}"
                               :color="settings.button_hover_text_color" title="Button text color on hover"/>
                </div>
              </div>
            </div>

            <div class="form-group row  form-group-last">
              <label class="col-xl-4 col-lg-3 col-form-label">Show popup</label>
              <div class="col-lg-9 col-xl-6">
                <div class="kt-input-icon kt-input-icon--right">
                  <div class="kt-switch_block ">
                  <span
                      :class="`kt-switch kt-switch--sm`"
                      @click="e => onToggle(e, 'show_popup')"
                  >
                    <label class="mb-0">
                      <input
                          type="checkbox"
                          :checked="!!settings.show_popup"
                          name=""
                      />
                      <span/>
                    </label>
                  </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="settings.show_popup" class="mt-4">

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Popup title</label>
                <div class="col-lg-9 col-xl-6">
                  <input
                      class="form-control"
                      v-model="settings.popup_title"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Popup description</label>
                <div class="col-lg-9 col-xl-6">
                  <input
                      class="form-control"
                      v-model="settings.popup_description"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Popup "Continue shopping" button text</label>
                <div class="col-lg-9 col-xl-6">
                  <input
                      class="form-control"
                      v-model="settings.popup_continue_text"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Popup "Go to checkout" button text</label>
                <div class="col-lg-9 col-xl-6">
                  <input
                      class="form-control"
                      v-model="settings.popup_checkout_text"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-4 col-lg-3 col-form-label">Popup "Delete item" text</label>
                <div class="col-lg-9 col-xl-6">
                  <input
                      class="form-control"
                      v-model="settings.popup_delete_text"
                  />
                </div>
              </div>

              <div class="form-group row  form-group-last">
                <label class="col-xl-4 col-lg-3 col-form-label">Popup "Cart Subtotal" text</label>
                <div class="col-lg-9 col-xl-6">
                  <input
                      class="form-control"
                      v-model="settings.popup_subtotal_text"
                  />
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="kt-portlet__foot">
          <div class="kt-form__actions">
            <div class="row">
              <div class="col-xl-4 col-lg-3"></div>
              <div class="col-lg-9 col-xl-6">
                <button
                    type="submit"
                    id="createButton"
                    :disabled="isEditingSettings"
                    :class=" `btn btn-success ${isEditingSettings &&' kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark'}` "
                >
                  Save
                </button>&nbsp;
                <button
                    @click="onCancelClick()"
                    id="cancelButton"
                    type="button"
                    :class="`btn btn-secondary`"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>

</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from "vue-select";

import ColorPicker from '../components/ColorPicker';
import Loader from '../components/Loader';
import {LOAD_SETTINGS, EDIT} from '../store/modules/shop/types';
import {requestStatus} from "@/services/services";

export default {
  name: "Settings",
  components: {vSelect, ColorPicker, Loader},
  data: () => ({
    settings: {
      button_bounce: null,
      button_color: "",
      button_hover_color: "",
      button_hover_text_color: "",
      button_position: "",
      button_text: "",
      button_text_color: "",
      custom_styles: false,
      enabled: true,
      popup_checkout_text: "",
      popup_continue_text: "",
      popup_delete_text: "",
      popup_description: "",
      popup_subtotal_text: "",
      popup_title: "",
      show_popup: false
    },
    isLoadingSettings: false,
    isEditingSettings: false,
  }),

  computed: {
    ...mapState({
      shopSettings: state => state.shop.settings,
      status: state => state.shop.status
    }),
    button_position_option() {
      return [{
        label: 'Append after Add to cart button',
        value: 'append'
      }, {
        label: 'Replace Add to cart button',
        value: 'replace'
      }]
    },
    button_bounce_option() {
      return [{
        label: 'Shake bottom',
        value: 'shake_bottom'
      }, {
        label: 'Shake horizontal',
        value: 'shake_horizontal'
      }, {
        label: 'Shake vertical',
        value: 'shake_vertical'
      }]
    }
  },

  methods: {
    ...mapActions({
      loadSettings: `shop/${LOAD_SETTINGS}`,
      onEdit: `shop/${EDIT}`,
    }),
    onSave(e) {
      e.preventDefault();
      this.onEdit(this.settings);
    },
    onCancelClick() {
      this.settings = {...this.shopSettings};
    },
    onToggle(e, field) {
      e.preventDefault();
      this.settings[field] = !this.settings[field];
    }
  },
  watch: {
    shopSettings(newValue) {
      this.settings = {...newValue};
    },
    status(newValue) {
      this.isLoadingSettings = newValue.load_settings === requestStatus.loading;
      this.isEditingSettings = newValue.edit_settings === requestStatus.loading;
      if (newValue.change_field === 'edit_settings' && newValue.edit_settings === requestStatus.success) {
        this.$toaster.success("Success update settings");
      }
    }
  },
  created: function () {
    this.loadSettings();
  }
}
</script>


<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>